import React from "react";
import {
  Image,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";

class LanguageDropdown extends React.Component {
  constructor(props) {
    super(props);
    const { fields, sitecoreContext } = this.props;
    this.state = {
      showLanguageDropdown: false,
      currentLanguage: fields.currentCountry.fields.languageList.find(
        ({ fields }) =>
          sitecoreContext.language.includes(
            fields.sitecoreKey.value || fields.key.value,
          ),
      ),
    };
  }

  toggleShowLanguage = () => {
    this.setState((prevState) => {
      return {
        showLanguageDropdown: !prevState.showLanguageDropdown,
      };
    });
  };

  render() {
    const { fields, toggleCountrySelector, isProfileEnabled, isUserLoggedIn } =
      this.props;
    const { currentLanguage, showLanguageDropdown } = this.state;
    const { icon, languageList, link } = fields.currentCountry.fields;

    const userCurrentLanguageText =
      typeof currentLanguage !== "undefined"
        ? currentLanguage.fields.text.value
        : "No Language";

    const userCurrentLanguageKey =
      typeof currentLanguage !== "undefined"
        ? currentLanguage.fields.key.value
        : "No Language";

    // if profile enabled and user logged in and langauge = 1 (ie, only 1 language option),
    if (isProfileEnabled && isUserLoggedIn && languageList.length === 1) {
      return (
        <li className="contact nav-region-dropdown">
          <div className="nav-dropdown-toggle">
            <span
              id="nav-region-dropdown__cta"
              className="nav-region-dropdown__cta"
              aria-hidden="true"
            >
              <img
                src={icon.value.src}
                alt={icon.value.alt}
                className="region-flag"
              />
              {userCurrentLanguageText}
            </span>
          </div>
        </li>
      );
    }

    const rootItemDestination = fields.rootItem.url.replace(
      /\/[a-z]{2,}\/[a-z]{2}\//,
      "",
    );

    return (
      <li
        className={`contact nav-region-dropdown ${
          showLanguageDropdown ? "is-open" : ""
        }`}
      >
        <button
          onClick={() => {
            this.toggleShowLanguage();
            toggleCountrySelector(false);
          }}
          className="nav-dropdown-toggle"
          type="button"
          id="dropdownCountryMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-label={fields.languageSelectAria.value}
        >
          <span
            id="nav-region-dropdown__cta"
            className="nav-region-dropdown__cta"
            aria-hidden="true"
          >
            <img
              src={icon.value.src}
              alt={icon.value.alt}
              className="region-flag"
            />
            {userCurrentLanguageText}
          </span>
        </button>
        {showLanguageDropdown && (
          <div
            className="dropdown-menu open"
            aria-labelledby="dropdownCountryMenuButton"
          >
            <div
              className="mobile-overlay"
              onClick={() => this.toggleShowLanguage()}
            />
            <div className="dropdown-items">
              {languageList
                .filter(
                  (language) =>
                    language.fields.key.value !== userCurrentLanguageKey,
                )
                .map((language) => (
                  <a
                    key={language.fields.key.value}
                    className="dropdown-item"
                    href={`/${fields.currentCountry.fields.key.value}/${language.fields.key.value}/${rootItemDestination}`}
                  >
                    <img src={icon.value.src} alt={icon.value.alt} />
                    <span className="dropdown-item-title">
                      {language.fields.text.value}
                    </span>
                  </a>
                ))}

              {!(isProfileEnabled && isUserLoggedIn) &&
                fields.languageOtherText?.value && (
                  <button
                    type="button"
                    onClick={() => {
                      toggleCountrySelector(true);
                      this.toggleShowLanguage();
                    }}
                    className="dropdown-item item-country"
                  >
                    <Image field={fields.languageOtherIcon} />
                    <span className="dropdown-item-title">
                      <Text field={fields.languageOtherText} />
                    </span>
                  </button>
                )}
              <button
                type="button"
                onClick={() => {
                  this.toggleShowLanguage();
                }}
                className="dropdown-item item-country close"
              >
                <span className="dropdown-item-title">
                  <Text field={fields.closeText} />
                </span>
              </button>
            </div>
          </div>
        )}
      </li>
    );
  }
}

export default withSitecoreContext()(LanguageDropdown);
